<template>
  <div id="payorder">
    <div class="contents">
      <div class="head">
        <p>订单提交成功</p>
        <el-steps
          style="width: 400px"
          align-center
          :space="200"
          :active="3"
          finish-status="success"
        >
          <el-step title="我的购物车"></el-step>
          <el-step title="核对订单信息"></el-step>
          <el-step title="成功提交订单"></el-step>
        </el-steps>
      </div>
      <div class="tips">
        所有货物请在送货员在场时开箱验货再签收，如有破损及时拍照（破损货物/快递面单/货物外箱）并联系客服人员，如未当面开箱验货，破损不予赔付，自行承担
      </div>
      <div class="middle">
        <div class="imgbox">
          <img src="@/assets/img/cart/ddtjcg.svg" alt="" />
        </div>
        <p class="text1">订单提交成功</p>
        <p class="text2">{{orderData.pay_notice}}</p>
        <div class="card">
          <div class="ddbh">
            <div>
              <div class="ddbh-box">
                <div class="ddicon"><img src="@/assets/img/cart/ddbh.png" alt="" /></div>
                <span>订单编号：{{ orderData.ddbh }}</span>
                <span class="copy" @click="copy">复制</span>
              </div>
              <p class="yfk">
                <img src="@/assets/img/cart/yfje.png" alt="" /> 应付金额：<span
                  >￥{{ orderData.pay_amount }}</span
                >
              </p>
            </div>
            <div>
              <div class="btnbox">
                <router-link  :to="{ path: '/index/subRouter/order'}"><p>查看订单</p></router-link>
                <p @click="openpayment">立即支付</p>
              </div>
            </div>
          </div>
        </div>
        <p class="title">收货信息</p>
        <div class="card" v-if="orderData.address">
          <p>
            <span class="justify grey">收货人</span>：{{
              orderData.address.name
            }}
          </p>
          <p>
            <span class="justify grey">联系方式</span>：{{
              orderData.address.phone
            }}
          </p>
          <p>
            <span class="justify grey">配送方式</span>：{{
              orderData.address.deliverymethod
            }}
          </p>
          <p>
            <span class="justify grey">收货地址</span>：{{
              orderData.address.address_str
            }}
          </p>
        </div>
      </div>
    </div>
    <!-- 支付对话框 -->
    <el-dialog :visible.sync="dialog_zf" width="600px" @close="judgmentIsPay">
      <div class="head">
        <template v-for="(item,index) in payList" >
          <div
            class="head-text"
            v-if="item.type !== 7"
            :key="index"
            @click="choose_zffs(item,index)"
            :class="{
              'head-text-a': isOnline == index ? true : false,
            }"
          >
            <p class="">{{ item.title }}</p>
            <div></div>
        </div>
        <div
            class="head-text"
            v-else-if="item.type == 7"
            :key="index"
            @click="choose_zffs(item,9)"
            :class="{
              'head-text-a': isOnline == 9 ? true : false,
            }"
          >
            <p class="">{{ item.title }}</p>
            <div></div>
        </div>
        </template>
      </div>
      <template v-if="isOnline != 9">
        <div class="ewm">
          <img :src="ewmData.logo" alt="" />
        </div>
        <div class="ewm_zc" v-if="type == 1">
          <span>二维码支持</span>
          <img src="@/assets/img/cart/weixin.png" alt="" />
          <img src="@/assets/img/cart/zfb.png" alt="" />
        </div>
      </template>
      <template v-else>
        <div class="card_zz">
          <p><span class="justify">账户</span>：{{transferMoney.bank_account}}</p>
          <p><span class="justify">开户行</span>：{{transferMoney.bank_name}}</p>
          <p>
            <span class="justify">账号</span>：<span class="col198"
              >{{transferMoney.bank_no}}</span
            >
          </p>
        </div>
      </template>
      <div v-if="isOnline != 9" class="wczf-box">
        <div @click="judgmentIsPay('1')" class="wczf">我已完成支付</div>
        <div @click="refresh_ewm" class="wczf">刷新二维码</div>
      </div>
      <div v-else style="text-align: center; color: gray">
        <i class="el-icon-info"></i> {{transferMoney.tips}}
      </div>
    </el-dialog>
    <el-dialog class="error" :visible.sync="dialog_sb" width="30%" center>
      <div class="errorbox">
        <img src="@/assets/img/cart/zfsb.svg" alt="" />
        <p>支付失败</p>
        <p>订单支付失败，请重新支付</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="btns" @click="cancel">取消支付</el-button>
        <el-button class="btns" type="primary" @click="repay"
          >重新支付</el-button
        >
      </span>
    </el-dialog>
    <el-dialog class="error" :visible.sync="dialog_cg" width="30%" center>
      <div class="errorbox">
        <img src="@/assets/img/cart/zfcg.svg" alt="" />
        <p>支付成功</p>
        <p>订单已支付完成，请勿重复支付</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="btns" @click="jumpOrderList('https://scjzyt.com/')"
          >返回首页</el-button
        >
        <el-button
          class="btns"
          type="primary"
          @click="jumpOrderList('https://scjzyt.com/order.html')"
          >查看订单</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/api/request";
export default {
  data() {
    return {
      token: "",
      urlData: {},
      dialog_zf: false,
      dialog_sb: false,
      dialog_cg: false,
      isPay: false,
      isOnline: 0, //9：对公转账
      orderData: {}, //订单数据
      ewmData: { logo: "https://ytpub.oss-cn-shenzhen.aliyuncs.com/newPc/jzz.png" }, //聚合二维码数据
      type: 2, //1：聚合支付，2：单独二维码支付
      timer: null, //定时判断用户是否支付完成的定时任务状态
      payList:[], //支付方式数据
      transferMoney:{}, //转账信息
    };
  },
  created() {
    if(this.$route.query.ddbh){
        this.getOrderByid(this.$route.query.ddbh);
    }
      
  },
  methods: {
    // 根据订单id获取订单详情
    getOrderByid(ddbh) {
      this.$api('cart.getPay',{ddbh}).then((res) => {
        if (res.code == 200) {
          this.orderData = res.data;
          this.payList = res.data.list; //支付方式数据 
          let index = res.data.list.findIndex((item) => item.is_default == 101);
          this.isOnline = index
        } else {
          this.$message({
            message: res.desc,
            type: "warning",
            offset: "380",
          });
          this.$router.go(-1); //返回上一页
        }
      });
    },
    // 复制
    copy() {
      navigator.clipboard
        .writeText(this.orderData.ddbh)
        .then(() => {
          this.$message({
            message: "复制成功",
            type: "success",
            offset: "380",
          });
        })
        .catch(() => {
          this.$message({
            message: "复制失败",
            type: "warning",
            offset: "380",
          });
        });
    },
    // 获取二维码进行支付
    payorder(data) {
      if (data.url) {
        let url = data.url.substr(3, data.url.length - 1);
        const arg = {
          url: url,
          method: "post",
          params: {
            ddbh: this.orderData.ddbh,
          },
        };
        console.log("支付信息参数----", arg);
        request(arg).then((r) => {
          this.ewmData = r.data;
          // console.log(r);
        });
      } else {
        this.$message.error("支付出错啦~");
        this.type = 101;
        this.current = 1;
      }
    },
    // // 获取微信二维码
    // getWxEwm() {
    //   this.$api({
    //     method: "post",
    //     url: "https://newapp.api.scjzyt.com/order/pay/wx",
    //     data: { id: this.urlData.orderid },
    //   }).then((res) => {
    //     if (res.code == 200) {
    //       this.ewmData = res.data;
    //     } else if (res.code == "201") {
    //     } else {
    //       this.$message({
    //         message: res.desc,
    //         type: "warning",
    //         offset: "380",
    //       });
    //     }
    //   });
    // },
    // 获取支付宝二维码
    // getZfbEwm() {
    //   this.$api({
    //     method: "post",
    //     url: "https://newapp.api.scjzyt.com/order/pay/zfb",
    //     data: { id: this.urlData.orderid },
    //     headers: {
    //       token: this.token,
    //     },
    //   }).then((res) => {
    //     if (res.code == 200) {
    //       this.ewmData = res.data;
    //     } else if (res.code == "201") {
    //     } else {
    //       this.$message({
    //         message: res.desc,
    //         type: "warning",
    //         offset: "380",
    //       });
    //     }
    //   });
    // },
    // 刷新二维码
    refresh_ewm() {
      this.ewmData = Object.assign(this.ewmData, {
        logo: "https://ytpub.oss-cn-shenzhen.aliyuncs.com/newPc/jzz.png",
      });
      this.payorder(this.payList[this.isOnline])
    },
    // 切换支付方式
    choose_zffs(item,index) {
      this.isOnline = index;
      if(item.type !=7){
        this.ewmData = Object.assign(this.ewmData, {
        logo: "https://ytpub.oss-cn-shenzhen.aliyuncs.com/newPc/jzz.png",
      });
      this.payorder(this.payList[this.isOnline])
      }else{
        let dgzzIndex= this.payList.findIndex((e)=>e.type==7)
        this.transferMoney = this.payList[dgzzIndex].bankinfo
        console.log(this.transferMoney)
      }
    },
    // 判断是否已完成支付事件
    judgmentIsPay(type) {
      clearInterval(this.timer);
      if (type === "1") {
        this.orderIsPay();
      }
    },
    // 查询支付结果
    getPayStatus(ddbh) {
      this.$api("cart.payStatus", { ddbh: ddbh }).then((res) => {
        if (res.data == 101) {
          this.$confirm("订单支付失败", "提示", {
            confirmButtonText: "关闭",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$router.push({
                path: "/index/subRouter/order",
                query: { index: 1 },
              });
            })
            .catch(() => {
              this.$router.push({
                path: "/index/subRouter/order",
                query: { index: 1 },
              });
            });
        }
        if (res.data == 102) {
          this.$confirm("订单支付失败", "提示", {
            confirmButtonText: "关闭",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$router.push({
                path: "/index/subRouter/order",
                query: { index: 2 },
              });
            })
            .catch(() => {
              this.$router.push({
                path: "/index/subRouter/order",
                query: { index: 2 },
              });
            });
        }
      });
    },
    // 判断是否支付成功
    orderIsPay(type) {
      this.$api("cart.payStatus",{ddbh:this.orderData.ddbh}).then((res) => {
        if (res.code == 200) {
          if (res.data == "101") {
            // join表示在一进入页面和定时任务的时候调用
            if (type != "join") {
              this.dialog_sb = true;
            }
          } else {
            clearInterval(this.timer);
            this.dialog_cg = true;
            this.isPay = true;
          }
        } else {
          this.$message({
            message: res.desc,
            type: "warning",
            offset: "380",
          });
        }
      });
    },
    // 打开支付模态框
    openpayment() {
      
      if (this.isPay) {
        this.dialog_cg = true;
      } else {
        this.payorder(this.payList[this.isOnline])
        this.dialog_zf = true;
        this.timer = setInterval(() => {
          this.orderIsPay("join");
        }, 2000);
      }
    },
    // 重新支付事件
    repay() {
      this.dialog_sb = false;
      this.timer = setInterval(() => {
        this.orderIsPay("join");
      }, 2000);
    },
    // 取消支付
    cancel() {
      this.dialog_zf = false;
      this.dialog_sb = false;
    },
    // 跳转订单列表
    jumpOrderList(url) {
      location.href = url;
    },
  },
};
</script>

<style lang="less" scoped>
#payorder {
  background-color: #f9f9f9;
  .el-dialog {
    .head {
      display: flex;
      padding: 0 90px;
      font-size: 20px;
      font-weight: bold;
      justify-content: space-between;

      .head-text {
        cursor: pointer;

        > p {
          color: #999;
        }

        > div {
          opacity: 0;
          margin: 0 auto;
          width: 48px;
          height: 3px;
          border-radius: 2px;
          margin-top: 5px;
        }
      }

      .head-text-a {
        > p {
          color: #198c8b;
        }

        > div {
          opacity: 1;
          background-color: #198c8b;
        }
      }
    }

    .ewm {
      width: 200px;
      height: 200px;
      margin: 20px auto 0px;

      > img {
        width: 100%;
        height: 100%;
      }
    }

    .ewm_zc {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      margin-top: 3px;

      > img {
        width: 20px;
        margin: 0 2px;
      }
    }

    .wczf-box {
      display: flex;
      justify-content: center;
      margin: 20px auto;
    }
    .wczf {
      width: 150px;
      height: 40px;
      line-height: 40px;
      font-size: 16px;
      text-align: center;
      cursor: pointer;
      border: 1px solid #198c8b;
      color: #198c8b;
      border-radius: 8px;
      margin: 0 10px;
    }

    .card_zz {
      padding: 10px;
      border: 1px solid #198c8b;
      background-color: #f9f9f9;
      border-radius: 5px;
      margin-top: 20px;
      margin-bottom: 20px;
      > p {
        .justify {
          text-align-last: justify;
          display: inline-block;
          width: 46px;
        }

        .col198 {
          color: #198c8b;
          font-size: 20px;
          font-weight: bold;
        }

        &:nth-child(2) {
          margin: 20px 0;
        }
      }
    }
  }

  .el-dialog__header {
    padding: 5px 0px;
  }

  .el-dialog__body {
    padding: 13px 20px;
  }

  .error {
    .btns {
      padding: 15px 52px;

      > span {
        color: #606266;
      }

      &:nth-child(1) {
        margin-right: 50px;
      }

      &:nth-child(2) {
        border: 1px solid #198c8b;
        background-color: #198c8b;

        > span {
          color: #fff;
        }
      }
    }

    .errorbox {
      width: 250px;
      margin: 0 auto;

      > img {
        width: 250px;
      }

      > p {
        text-align: center;

        &:nth-of-type(1) {
          font-size: 20px;
          color: #000;
        }

        &:nth-of-type(2) {
          color: #999;
          font-size: 14px;
        }
      }
    }
  }
}

.contents {
  width: 1200px;
  margin: 0 auto;
  position: relative;
  padding: 0px 20px;
  .head {
    padding: 16px 0;
    display: flex;
    width: 100%;
    justify-content: space-between;

    > p {
      font-size: 22px;
      color: #198c8b;
    }
  }

  .tips {
    width: 100%;
    height: 42px;
    text-align: center;
    line-height: 42px;
    background-color: #198c8b;
    border-radius: 4px;
    color: #fff;
  }

  .middle {
    margin-top: 20px;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
    padding: 0 20px 20px;

    .imgbox {
      width: 130px;
      overflow: hidden;
      margin: 30px auto 10px;

      > img {
        width: 100%;
      }
    }

    .text1 {
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      color: #198c8b;
    }

    .text2 {
      text-align: center;
      color: #999;
      font-size: 12px;
      margin-top: 4px;
      margin-bottom: 25px;
    }

    .card {
      width: 100%;
      box-sizing: border-box;
      border: 1px solid #e4e4e4;
      border-radius: 5px;
      padding: 10px 20px;
      margin-top: 5px;

      .ddbh {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .yfk {
          font-size: 16px;
          display: flex;
          align-items: center;
          margin-top: 5px;

          > img {
            width: 17px;
            margin-right: 2px;
          }

          > span {
            font-size: 18px;
            color: red;
          }
        }

        .ddbh-box {
          display: flex;
          align-items: center;
        }

        .ddicon {
          vertical-align: top;
        }

        .btnbox {
          width: 230px;
          display: flex;
          justify-content: space-between;

          P {
            padding: 10px 20px;
            text-align: center;
            border-radius: 4px;
            background-color: red;
            opacity: 0.75;
            color: #fff;
            cursor: pointer;

            &:nth-child(1) {
              background-color: #999;
            }
          }
        }

        .copy {
          color: #999;
          border: 1px solid #999;
          display: inline-block;
          padding: 1px 5px;
          border-radius: 4px;
          font-size: 14px;
          cursor: pointer;
          margin-left: 15px;
        }
      }

      > p {
        line-height: 25px;
        font-size: 14px;
        .justify {
          text-align-last: justify;
          display: inline-block;
          width: 60px;
        }
      }
    }

    .title {
      font-size: 20px;
      margin-top: 20px;
    }
  }
  .grey {
    color: #999;
  }

  /deep/.head{
    .el-step__title.is-process {
    color: #198c8b;
    font-size: 14px;
    font-weight: normal;
  }

  .el-step__title.is-success {
    color: #198c8b;
    font-size: 14px;
  }

  .el-step__head.is-process {
    border-color: #198c8b;

    .el-step__icon-inner {
      color: #198c8b;
    }
  }

  .el-step__title {
    font-size: 14px;
  }

  .is-success {
    .el-step__line {
      background-color: #198c8b;
    }
  }

  .el-step__head.is-success {
    color: #198c8b;
    border-color: #198c8b;

    .el-step__icon-inner {
      color: #198c8b;
    }
  }

  .el-step__icon {
    color: #c0c4cc;
  }
}
}
</style>